import React from "react"

export default function Acquista() {
  return (
    <section className="section-acquista">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column is-three-fifths">
            <h1>DA ZERO<br />AL BRAND</h1>
            <h3>Guida completa al marketing strategico,<br/>dal posizionamento alla comunicazione.</h3>
            <p className="mb-6"><a rel="noreferrer" target="_blank" href="https://www.amazon.it/Zero-Brand-strategico-Posizionamento-Comunicazione/dp/8868958635/ref=sr_1_1?__mk_it_IT=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=da+zero+al+brand&qid=1604353796&s=digital-text&sr=1-1-catcorr" >Acquista</a></p>
          </div>
        </div>
      </div>
    </section>
  )
}
